import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MobileImagePage from "./MobileImagePage";
import Page1 from "./Page1";
import OrderForm from "./OrderForm"
import PaymentSuccessPage from "./PaymentSuccessPage";
import PaymentSuccessPageFpx from "./PaymentSuccessPageFpx";
import ColorPicker from "./ColorPicker";
import ColorPicker14 from "./ColorPicker14";
// import TrackingPage from "./TrackingPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/promosi" element={<MobileImagePage />} />
        <Route path="/" element={<Page1 />} />
        <Route path="/orderform1" element={<OrderForm />} />
        <Route path="/ColorPicker" element={<ColorPicker />} />
        <Route path="/ColorPicker14" element={<ColorPicker14 />} />
        <Route path="/PaymentSuccessPagePaymentSuccessPagePaymentSuccessPagePaymentSuccessPage" element={<PaymentSuccessPage />} />
        <Route path="/PaymentSuccessPageFpx" element={<PaymentSuccessPageFpx />} />
        {/* <Route path="/TrackingAiziraMY" element={<TrackingPage />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
