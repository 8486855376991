import React from 'react';

const SelectedItemsDisplay = ({ selectedData }) => {
  // Extract size and selected colors from the selectedData
  const size = selectedData?.[0]?.size;
  const selectedColors = selectedData?.slice(1); // Everything except the first item (size)

  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>Saiz : {size}</h3>

      <h3 style={styles.heading}>Warna :</h3>
      <div style={styles.colorDisplayContainer}>
        {selectedColors.map((color) => (
          <div
            key={color.uniqueId}
            style={{
              ...styles.colorBox,
              backgroundColor: color.color,
            }}
          >
            {/* Optionally add an icon if needed */}
            <div>{color.icon}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Styles object
const styles = {
  container: {
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    padding: '15px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    marginTop: '25px',
  },
  heading: {
    fontSize: '18px',
    marginBottom: '12px',
    fontWeight: 'bold',
    color: '#333',
  },
  colorDisplayContainer: {
    display: 'flex',
    gap: '12px',
    marginBottom: '24px',
    flexWrap: 'wrap',
  },
  colorBox: {
    width: '48px',
    height: '48px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'border-color 0.3s ease',
  },
};

export default SelectedItemsDisplay;
