import React, { useState, useEffect, useRef } from 'react';
import './OrderForm.css';
import { supabase } from './supabaseClient';
import img9 from './img1/BARANG-SAMPAI-BARU-BAYAR-01.gif';
import img10 from './img1/fpx-logo.png'
import img11 from './img1/Logo-FPX.png'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SelectedItemsDisplay from './SelectedItemsDisplay';
import logo1 from './img1/hibiscus.png'


function OrderForm() {

  const hasTrackedPurchase = useRef(false);

  useEffect(() => {
    if (window.fbq && !hasTrackedPurchase.current) {
      window.fbq('track', 'AddToCart', {
        value: 99.00,
        currency: 'MYR'
      });
      hasTrackedPurchase.current = true;
    }
  }, []);

  const handleClickAPI = () => {
    // Trigger the Facebook Pixel event
    if (window.fbq) {
      window.fbq('track', 'AddPaymentInfo');
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { combinedData } = location.state || {}; // Access combinedData from the state
  const [idnumber, setIdnumber] = useState('')
  const [price, setPrice] = useState('')

  const [formData, setFormData] = useState({
    booking_id: '',
    pakej: "SET Combo 7 Helai",
    saiz: "",
    name: '',
    email: '',
    phone: '',
    poskod: '',
    address: '',
    state: '',
    paymentMethod: 'FPX',
    harga: price
  });
  const [loading, setLoading] = useState(false); // Added loading state

    // This effect will run every time 'idnumber' changes
    useEffect(() => {
      if (idnumber) {
        console.log("Updated ID:", idnumber);  // Log the updated 'idnumber' here
      }
    }, [idnumber]);  // Dependency array ensures the effect runs when 'idnumber' changes

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);
    console.log(combinedData)

  }, []);

  const malaysianStates = [
    'Johor', 'Kedah', 'Kelantan', 'Melaka', 'Negeri Sembilan',
    'Pahang', 'Perak', 'Perlis', 'Pulau Pinang', 'Sabah',
    'Sarawak', 'Selangor', 'Terengganu', 'WP Kuala Lumpur',
    'WP Labuan', 'WP Putrajaya'
  ];
  const saiz = [
    'M', 'L'
  ];



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };
  

  const handleGenerateBookingId = () => {
    const bookingId = generateRandomString();
  
    // Check if the new booking ID is different from the existing one before updating the state
    if (formData.booking_id !== bookingId) {
      setFormData(prevState => ({
        ...prevState,
        booking_id: bookingId,
      }));
    }
  };
  


  const generateRandomString = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomString = '';
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      randomString += chars[randomIndex];
    }
  
    const now = new Date();
    const dateTime = `${String(now.getFullYear()).slice(-2)}${String(now.getMonth() + 1).padStart(2, '0')}` +
                     `${String(now.getDate()).padStart(2, '0')}${String(now.getHours()).padStart(2, '0')}` +
                     `${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;
  
    return `AIZIRA-${randomString}${dateTime}`;
  };
  
  
  
  useEffect(() => {
    // Log the booking_id only if it has changed
    if (formData.booking_id) {
      // console.log("Updated booking ID:", formData.booking_id);
    }
  }, [formData.booking_id]); // Trigger the effect when booking_id changes

  const postPaymentDetails = async () => {
    const url = 'https://backendam-xeem.vercel.app/api/paymentCheck';
    const paymentData = {
      refno: '1234567890',
      status: 1,
      reason: 'Payment successful',
      billcode: 'your_billcode_here',
      order_id: 1212312313,
      amount: 100,
      transaction_time: '2025-01-02T12:34:56Z',
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentData),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Payment details successfully posted:', data);
      } else {
        console.error('Failed to post payment details:', response.statusText);
      }
    } catch (error) {
      console.error('Error posting payment details:', error);
    }
  };
  
  

  async function processCheckout(jsonData, orderid) {
    try {
        handleGenerateBookingId(); // This might be synchronous or asynchronous, check its definition.

        console.log(jsonData.order_number);
        console.log("Rokoo");

        // Check if the payment method is 'FPX'
        if (formData.paymentMethod === 'FPX') {
            // Insert data into Supabase
            const { data, error } = await supabase
                .from('database1')
                .insert([{
                    json: jsonData,
                    payment: formData.paymentMethod,
                    order_number: jsonData.order_number.toString(),
                }])
                .select('id');

            // Error handling for Supabase
            if (error) {
                console.error('Error inserting data into Supabase:', error);
                alert('Failed to save order to the database. Please try again.');
                return;
            }

            // Extract the inserted row ID
            const insertedRowId = data[0].id;
            setIdnumber(insertedRowId.toString()); // Update state with the ID as a string

            console.log("den");
            console.log("den", insertedRowId.toString());

            // Prepare bill details
            const billDetails = {
                userSecretKey: process.env.REACT_APP_TOYYIB_SECRET_KEY,
                categoryCode: process.env.REACT_APP_TOYYIB_CATEGORY_ID,
                billName: "Aizira Promosi 2025",
                billDescription: "Aizira Promosi 2025",
                billPriceSetting: "1",
                billPayorInfo: "1",
                billTo: formData.name,
                billEmail: formData.email,
                billPhone: formData.phone,
                billExternalReferenceNo: insertedRowId, // Use the ID from state
                // billExternalReferenceNo: jsonData.order_number.toString(), // Use the ID from state
                billAmount: (price * 100).toString(), //REAL VALUE FOR PROD
                // billAmount: (1.1 * 100).toString(), //Dummy Value for Debug
                // billReturnUrl: `${process.env.REACT_APP_REMIX_PUBLIC_APP_URL}/PaymentSuccessPageFpx`,
                billReturnUrl: "https://backendam-xeem.vercel.app/api/paymentCheck",
                // billCallbackUrl: "https://denroko.requestcatcher.com/",
                billCallbackUrl: "https://backendam-xeem.vercel.app/api/paymentCheck",
                //billCallbackUrl: `${process.env.REACT_APP_REMIX_PUBLIC_APP_URL}/PaymentSuccessPageFpx`,
                billPaymentChannel: "2",
                billExpiryDays: "1",
            };

            console.log("Denny", billDetails.billExternalReferenceNo);
            console.log("Price of Product", billDetails.billAmount);
            console.log("Bill Details:", billDetails);


            // Create FormData =============================================================================================
            const formData1 = new FormData();
            for (const [key, value] of Object.entries(billDetails)) {
                formData1.append(key, value);
            }

            // Fetch request to create a bill
            const response = await fetch(`${process.env.REACT_APP_TOYYIB_URL}/index.php/api/createBill`, {
                method: "POST",
                body: formData1,
            });

            const bill = await response.json(); // Parse the response JSON
            console.log("Bill Monitor")
            console.log(JSON.stringify(bill, null, 2)); // Pretty print the JSON with 2 spaces indentation

            // Redirect to the checkout page
            const checkoutUrl = `${process.env.REACT_APP_TOYYIB_URL}/${bill[0].BillCode}`;
            console.log("checkoutUrl Monitor")
            console.log(checkoutUrl);
            window.location.href = checkoutUrl; // Redirect the user
            // Create FormData =============================================================================================
        }
    } catch (error) {
        console.log("Error during checkout:", error); // Log errors
    }
}

// Log price after it updates
useEffect(() => {
  if (combinedData.length < 13 && formData.paymentMethod == 'FPX'){
    setPrice(89);
  }
  else if (combinedData.length < 13 && formData.paymentMethod == 'COD'){
    setPrice(99);
  }
  else if (combinedData.length > 13 && formData.paymentMethod == 'FPX'){
    setPrice(139);
  }
  else if (combinedData.length > 13 && formData.paymentMethod == 'COD'){
    setPrice(149);
  }
  console.log("Price sebelum payment method is :", price);
}, [price]); // This will log the updated price whenever `price` changes.

  // Check if all form fields are filled and paymentMethod length is greater than 1
  const isFormValid = (
    formData.name &&
    formData.phone &&
    formData.email &&
    formData.poskod &&
    formData.address &&
    formData.state &&
    formData.paymentMethod.length > 1
  );

  
  const handleSubmit = async (e) => {
    //SET PRICE
    
    if (combinedData.length < 13 && formData.paymentMethod == 'FPX'){
      setPrice(89);
    }
    else if (combinedData.length < 13 && formData.paymentMethod == 'COD'){
      setPrice(99);
    }
    else if (combinedData.length > 13 && formData.paymentMethod == 'FPX'){
      setPrice(139);
    }
    else if (combinedData.length > 13 && formData.paymentMethod == 'COD'){
      setPrice(149);
    }

    console.log("Price selepas payment method is :", price);



    e.preventDefault();
    setLoading(true); // Set loading to true
    const randomstring = generateRandomString();

    setTimeout(async () => {

      if (
        formData.name &&
        formData.phone &&
        formData.email &&
        formData.poskod &&
        formData.address &&
        formData.state &&
        formData.paymentMethod.length > 1
      ) {
        const message = `
          Hai Saya Nak Book Tempahan Slimming Panties
          Order Number = ${randomstring}
          Saiz: ${formData.saiz}
          Nama: ${formData.name}
          Email: ${formData.email}
          Telefon: ${formData.phone}
          Alamat: ${formData.address}
          Poskod: ${formData.poskod}
          Negeri: ${formData.state}
          Pembayaran: ${formData.paymentMethod}
          Total Harga: ${price}
        `;
        const encodedMessage = encodeURIComponent(message);
        const warna = combinedData
        .filter(item => item.name) // Filter to include only items with a 'name' property
        .map(item => item.name)   // Extract the 'name' property
        .join(", ");              // Join names with commas

        const jsonData = {
          order_number: randomstring, // Replace with actual random string generation logic
          pakej: formData.pakej,
          saiz: combinedData[0].size, 
          warna: warna, // Include the joined names here
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          address: formData.address,
          poskod: formData.poskod,
          state: formData.state,
          payment_method: formData.paymentMethod,
          total_price: price + 10,
        };
        console.log("Den")

        if (formData.paymentMethod == 'COD'){
          const whatsapp = false;
          const orderid = jsonData.order_number;
          try {
            const { data, error } = await supabase
            .from('database1') // Replace with your table name
            .insert([{
              json: jsonData,  // Assuming jsonData is the data you want to insert into the 'json' column
              whatsapp: whatsapp, // Set whatsapp to false
              payment: formData.paymentMethod,
              order_number: orderid
            }]);

            if (error) {
              console.error('Error inserting data into Supabase:', error);
              alert('Failed to save order to the database. Please try again.');
              return;
            }
      
            console.log('Data successfully inserted:', data);
            navigate('/PaymentSuccessPagePaymentSuccessPagePaymentSuccessPagePaymentSuccessPage', { state: jsonData });

          } catch (err) {
            console.error('Error:', err);
          }
        }
        else{
          const orderid = jsonData.order_number;
          try {
            try {
                await processCheckout(jsonData, orderid); // Call the checkout process
            } catch (error) {
                console.error("Error during checkout:", error);
                // Handle error (e.g., show a message or redirect)
            }

          } catch (err) {
            console.error('Error:', err);
          }

        }

      } else {
        console.log('Please fill in all fields and select Cash on Delivery');
        alert('Please fill in all fields!');
      }

      
      setLoading(true); // Set loading to false
    }, 1000); // Wait for 1 second
  };

  // Initialize state with an array for 7 color selections
  const [selectedColors, setSelectedColors] = useState(Array(7).fill(''));

  // Function to handle color change for a specific index
  const handleColorChange = (index, color) => {
    const newSelectedColors = [...selectedColors];
    newSelectedColors[index] = color;
    setSelectedColors(newSelectedColors);
  };

  

  return (
    <div className="form-container">
      <div className="form-wrapper">
      <img src={logo1} alt="Cash on Delivery" style={{height: '60px'}} />
      <h1 style={{ color: "#C03765", fontSize: "26px", marginTop: '-10px', fontFamily: "'Sofia Sans Extra Condensed', sans-serif" }}>
        Aizira MY
      </h1>
        <h2 style={{ color: "#C03765", fontSize: "26px", marginTop: '0px', fontFamily: "'Sofia Sans Extra Condensed', sans-serif" }}>Checkout Form</h2>
        <h3 style={{ color: "#C03765", fontSize: "26px", marginTop: '-30px', fontFamily: "'Sofia Sans Extra Condensed', sans-serif" }}>Sila Isi Maklumat Anda</h3>

        <form onSubmit={handleSubmit}>
          {/* <ProductImage /> */}
          <SelectedItemsDisplay selectedData={combinedData} />
          <h1 style={{ color: 'black', marginTop: '20px', fontStyle: 'inherit' }}>
            {price < 100 ? 'Set Trial 7 Helai' : 'Set Combo 14 Helai'}
          </h1>
          <h1 style={{ color: 'blue', marginTop: '-12px', fontSize: '20px' }}>
            {price < 100 ? 'RM99' : 'RM149'}
          </h1>
          <div className="form-section">
          </div>
          <div className="form-section">
            <h3>Maklumat Anda</h3>
            <div className="form-field">
              <label htmlFor="name">Nama Anda</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                placeholder="Nama Anda"
              />
            </div>
            <div className="form-field">
              <label htmlFor="email">Email Anda</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="you@example.com"
              />
            </div>
            <div className="form-field">
              <label htmlFor="phone">No Phone</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
                placeholder="01xxxxxxxx"
              />
            </div>
          </div>
          <div className="form-section">
            <h3>Address Information</h3>
            <div className="form-field">
              <label htmlFor="address">Alamat</label>
              <textarea
                id="address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
                rows={3}
                placeholder="Sila Isi Alamat Anda"
              />
            </div>
            <div className="form-field">
              <label htmlFor="poskod">Postal Code</label>
              <input
                type="text"
                id="poskod"
                name="poskod"
                value={formData.poskod}
                onChange={handleInputChange}
                required
                placeholder="50000"
              />
            </div>
            <div className="form-field">
              <label htmlFor="state">Negeri</label>
              <select
                id="state"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                required
              >
                <option value="">Sila Pilih Negeri</option>
                {malaysianStates.map(state => (
                  <option key={state} value={state}>{state}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-section">
            <h3>Payment Method</h3>
            <div className="payment-options">
              <div
                className={`payment-option ${formData.paymentMethod === 'COD' ? 'selected' : ''}`}
                onClick={() => setFormData(prev => ({ ...prev, paymentMethod: 'COD' }))} 
              >
                <div className="payment-content">
                {formData.paymentMethod === 'FPX' ? (
                  <h4>🔘</h4>
                  ) : (
                    <h4>✅</h4>
                  )}
                  <img src={img9} alt="Cash on Delivery" style={{ width: '200px', height: 'auto', marginTop: '5px' }} />

                  <h4>Cash on Delivery</h4>
                  <p>Barang Sampai Baru Bayar Kepada Posmen</p>
                  <p>- Penghantaran Percuma Untuk Masa Promosi Sahaja!</p>
                </div>
              </div>
              <div
                className={`payment-option ${formData.paymentMethod === 'FPX' ? 'selected' : ''}`}
                onClick={() => setFormData(prev => ({ ...prev, paymentMethod: 'FPX' }))} 
              >
                <div className="payment-content">
                {formData.paymentMethod === 'COD' ? (
                  <h4>🔘</h4>
                  ) : (
                    <h4>✅</h4>
                  )}
                  <img src={img11} alt="FPX" style={{ width: '200px', height: 'auto', marginTop: '10px' }} />
                  <img src={img10} alt="FPX" style={{ marginTop: '7px', marginBottom: '10px', width: '200px', height: 'auto' }} />

                  <h4>Online Banking - FPX</h4>
                  <p>Online Banking Payment Channel via DuitNow and FPX</p>
                  <p style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    Free Postage + <span className="blinking" style={{ backgroundColor: 'red', color: 'white', padding: '1px' }}>Diskaun RM10</span>
                  </p>
                </div>
              </div>
            </div>
            {formData.paymentMethod === 'FPX' ? (
              <h1 style={{ color: 'black', fontSize: '30px' }}>
                Total: <span style={{ color: 'red', textDecoration: 'line-through' }}>
                  {price < 100 ? 'RM99.00' : 'RM149.00'}
                </span> {price < 100 ? 'RM89.00' : 'RM139.00'}
              </h1>
            ) : (
              <h1 style={{ color: 'black', fontSize: '30px' }}>
                Total: {price < 100 ? 'RM99.00' : 'RM149.00'}
              </h1>
            )}

          </div>
          <button
  type="submit"
  className="submit-button"
  disabled={loading || !isFormValid} // Disable button if loading or form is invalid
  onClick={handleClickAPI}
  style={{
    backgroundColor: loading || !isFormValid ? 'gray' : '', // Set the background color to gray when loading or form is invalid
    cursor: loading || !isFormValid ? 'not-allowed' : 'pointer', // Change cursor to not-allowed if loading or form is invalid
  }}
>
  {loading ? 'Processing...' : 'Place Order'}
</button>
        </form>
      </div>

    </div>
  );
}

export default OrderForm;
