import React from 'react'
import image1 from "./img1/beforeafter.webp";
import image2 from "./img1/22.webp";
import image3 from "./img1/elastic.webp";

const Destinations = () => {
  return (
    <section className='destinations'>
      <div className='grid'>
        <div>
          <img src={image1} alt='destination-1' />
          <h3>Bentuk Badan Flawless, Tanpa Gym!</h3>
          <p>
          Kenapa? Slimming panties ni tak payah tunggu sebulan untuk nampak hasil — sarung je terus nampak ramping. Perut kempis, pinggul lebih kemas, dan paha lebih langsing dalam sekelip mata!
          </p>
        </div>

        <div>
          <img src={image2} alt='destination-2' />
          <h3>Selesa Gila Sampai Lupa Pakai</h3>
          <p>
          Kenapa? Material lembut, ringan, dan bernafas. Tak rasa melekit atau panas walaupun pakai seharian. Duduk lama? Tiada masalah — tiada kesan sakit atau ketat.
          </p>
        </div>

        <div>
          <img src={image3} alt='destination-3' />
          <h3>Confidence Level Naik Berkali Ganda</h3>
          <p>
          Kenapa? Bila badan nampak on-point, keyakinan automatik meletup! Slimming panties kami buat anda rasa fabulous dengan setiap outfit — tak kira baju pergi mall, kenduri or kerja.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Destinations
