import React from 'react';
import './CenteredContent.css';

// Title Component
const Title = ({ title, fontColor }) => <h1 className="title" style={{ color: fontColor }}>{title}</h1>;

// Description Component
const Description = ({ description, fontColor }) => <p className="description" style={{ color: fontColor }}>{description}</p>;

// Button Component
const Button = ({ buttonText }) => <button className="button">{buttonText}</button>;

const handleScroll = () => {
  let scrolled = 0;
  const scrollInterval = setInterval(() => {
    window.scrollBy(0, 10); // Scrolls down by 10px at a time
    scrolled += 10;
    
    // Stop scrolling after reaching 100px
    if (scrolled >= 300) {
      clearInterval(scrollInterval);
    }
  }, 10); // 10ms delay for smoothness
};

// CenteredContent Component
const CenteredContent = ({ title, description, buttonText, backgroundColor, fontColor }) => {
  return (
    <div className="container" style={{ backgroundColor }}>
      {title && <Title title={title} fontColor={fontColor} />}
      {description && <Description description={description} fontColor={fontColor} />}
      {buttonText && (
        <button 
        style={{color: 'white', backgroundColor: 'orange'}}
        onClick={handleScroll}>
          {buttonText}
        </button>
      )}
    </div>
  );
}

export default CenteredContent;
