// FAQMobileView.js
import React, { useState } from "react";
import "./FaqMobileView.css";

const FAQMobileView = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    { question: "Boleh ke pulang kalau saiz tak kena?", answer: "Boleh je, kalau tak puas hati, pulang dalam 14 hari lepas beli." },
    { question: "Macam mana nak check order saya?", answer: "Senang je, pakai nombor tracking yang kami bagi dalam whatsapp!" },
    { question: "Selesa ke panties ni pakai lama2?", answer: "Selesa gila! Bahan lembut, boleh pakai lama-lama." },
    { question: "Boleh pakai masa exercise tak?", answer: "Boleh sangat, seluar ni memang sesuai untuk pakai masa workout." },
  ];
  

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h1 className="faq-title">Frequently Asked Questions</h1>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <div className="faq-item" key={index}>
            <div
              className="faq-question"
              onClick={() => toggleAnswer(index)}
            >
              {item.question}
              <span className="faq-icon">
                {activeIndex === index ? "-" : "+"}
              </span>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQMobileView;