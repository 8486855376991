import React, { useState, useEffect, useRef } from 'react';
import imgg from './img1/confirm.png';
import { useLocation } from 'react-router-dom';
import { supabase } from './supabaseClient'; // Ensure supabase client is properly set up
import logo1 from './img1/hibiscus.png';

const PaymentSuccessPageFpx = () => {
  const [currentDate, setCurrentDate] = useState('');
  const [orderId, setOrderId] = useState('');
  const [statusId, setStatusId] = useState('');
  const location = useLocation();
  const [jsonData, setJsonData] = useState(null); // Initialize jsonData as null (not an array)
  const [fpxAmount, setFpxAmount] = useState(null);  // State to store fpx_amount


  const hasTrackedPurchase = useRef(false);

  useEffect(() => {
    const date = new Date().toLocaleString();
    setCurrentDate(date);

    // Get the current URL
    const url = window.location.href;
    console.log('URL:', url); // Log the URL to confirm it

    // Extract the order_id from the URL and ensure it's an integer
    const orderIdMatch = url.match(/order_id=(\d+)/i);
    if (orderIdMatch) {
      const orderId = parseInt(orderIdMatch[1], 10); // Ensure it's an integer
      if (!isNaN(orderId)) {
        setOrderId(orderId); // Set the orderId in state
        console.log('Extracted order_id:', orderId); // Optional: for debugging
      } else {
        console.error('Invalid order_id format');
      }
    }

    // Use regular expression to extract status_id
    const statusMatch = url.match(/[?&]status_id=(\d+)/);
    if (statusMatch) {
      setStatusId(statusMatch[1]);
    }
  }, []);

  useEffect(() => {
    // Call updateDatabase if orderId and statusId are available
    if (orderId && statusId) {
      updateDatabase();
    }
  }, [orderId, statusId]);

  const updateDatabase = async () => {
    try {
      // Check if orderId is valid and statusId is 1 (success)
      if (orderId && statusId === '1') {
        console.log("orderId anda:", orderId);
        console.log("statusId anda:", statusId);
        const fpxSuccess = true;
        const whatsapp = false;
  
        // Update the database
        const { data: updateData, error: updateError } = await supabase
          .from('database1')
          .update({ fpx_success: fpxSuccess, whatsapp: whatsapp }) // Assuming fpx_success is a boolean
          .eq('order_number', orderId);
  
        if (updateError) {
          console.error('Error updating the database:', updateError);
        } else {
          console.log('Database updated successfully:', updateData);
        }
  
        // Fetch JSON data associated with the orderId
        const { data: jsonData1, error: selectError } = await supabase
          .from('database1')
          .select('json')
          .eq('id', orderId);

          console.log(JSON.stringify(jsonData1, null, 2));
        if (selectError) {
          console.error('Error fetching data:', selectError);
        } else {
          console.log('Existing Data:', jsonData1); // Ensure orderId exists in the table
          if (jsonData1 && jsonData1.length > 0) {
            setJsonData(jsonData1[0].json); // Update state with the JSON data
          } else {
            console.log('No data found for the given orderId.');
          }
        }
      } else {
        console.log('Order ID is invalid or status is not success.');
      }
    } catch (error) {
      console.error('Error in try block:', error);
    }
  };

   // Effect to handle jsonData updates
   useEffect(() => {
    if (jsonData) {
      console.log("Updated JSON Data:", JSON.stringify(jsonData, null, 2));
    }
  }, [jsonData]); // This will trigger whenever jsonData is updated

  useEffect(() => {
    const getSupabaseAmount = async () => {
      // Log individual conditions
      console.log('jsonData:', jsonData);  // Check if jsonData is valid
      console.log('jsonData.total_price:', jsonData?.total_price);  // Check if total_price is present
  
      // Ensure jsonData exists and has total_price
      if (jsonData && jsonData.total_price && window.fbq && !hasTrackedPurchase.current) {
        const realAmount = parseFloat(jsonData.total_price); // Use total_price from jsonData
  
        // Log to verify the amount
        console.log('realAmount:', realAmount);
  
        // Check if realAmount is valid
        if (!isNaN(realAmount)) {
          console.log('Triggering Facebook Pixel Purchase Event with value:', realAmount);
          window.fbq('track', 'Purchase', {
            value: realAmount,
            currency: 'MYR'
          });
  
          // Set the ref to true to avoid triggering the event multiple times
          hasTrackedPurchase.current = true;
        } else {
          console.log('realAmount is invalid.');
        }
      } else {
        console.log('jsonData or total_price is missing, or Facebook Pixel is not available.');
      }
    };
  
    getSupabaseAmount();
  }, [jsonData]); // This will run when jsonData changes
  

useEffect(() => {
  console.log("Harga Barang: ", fpxAmount); // This will now log the updated value
}, [fpxAmount]); // Runs when fpxAmount changes
  


  const handleWhatsAppRedirect = () => {
    const phoneNumber = '601128459844';
    const message = `Hello Aizira Malaysia, I just submit an order!\n${JSON.stringify(jsonData, null, 2)}`;
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <div style={styles.container}>
      <img src={logo1} alt="Cash on Delivery" style={{height: '60px'}} />
      <h1 style={{ color: "#C03765", fontSize: "26px", marginTop: '-10px', fontFamily: "'Sofia Sans Extra Condensed', sans-serif" }}>
        Aizira MY
      </h1>
      <h1 style={styles.heading}>Terima Kasih!</h1>
      <p style={styles.date}>{currentDate}</p>
      <p style={styles.message}>
        Order anda akan diproses dan diposkan secepat mungkin.
      </p>
      <img src={imgg} alt="Cash on Delivery" style={styles.image} />
      <p style={styles.countdownText}>
        Staff kami akan whatsapp anda sebentar lagi untuk rujukan
      </p>
      <p style={styles.countdownText}>
        Atau klik butang di bawah
      </p>
      <button onClick={handleWhatsAppRedirect} style={styles.button}>
        Direct ke WhatsApp
      </button>
      {jsonData && (
        <div style={styles.tableContainer}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>Details</th>
                <th style={styles.tableHeader}>Tempahan</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(jsonData).map(([key, value]) => ( // Loop over jsonData entries
                <tr key={key}>
                  <td style={styles.tableCell}>{key.charAt(0).toUpperCase() + key.slice(1)}</td>
                  <td style={{ ...styles.tableCell, ...styles.wrappedCell }}>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

// Styling object remains unchanged
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '20px',
    maxWidth: '600px',
    margin: '0 auto',
    marginTop: '50px',
    backgroundColor: '#fff',
    borderRadius: '15px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif',
  },
  heading: {
    fontSize: '2.5rem',
    fontWeight: '600',
    color: '#e91e63',
    marginBottom: '10px',
    marginTop: '-25px'
  },
  date: {
    fontSize: '1rem',
    color: '#777',
    marginBottom: '20px',
  },
  message: {
    fontSize: '1.2rem',
    color: '#333',
    marginBottom: '30px',
    lineHeight: '1.6',
  },
  image: {
    width: '200px',
    height: 'auto',
    marginTop: '-30px',
    marginBottom: '-10px',
  },
  tableContainer: {
    overflowX: 'auto',
    width: '100%',
    marginBottom: '30px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    backgroundColor: '#fce4ec',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '10px',
    border: '1px solid #ddd',
  },
  tableCell: {
    padding: '10px',
    border: '1px solid #ddd',
    textAlign: 'left',
    verticalAlign: 'top',
  },
  wrappedCell: {
    maxWidth: '250px',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  countdownText: {
    marginTop: '20px',
    fontSize: '1.2rem',
    color: '#333',
    marginBottom: '10px',
  },
  button: {
    marginTop: '20px',
    padding: '12px 25px',
    backgroundColor: '#25d366',
    color: 'white',
    fontSize: '1.1rem',
    fontWeight: '600',
    border: 'none',
    borderRadius: '30px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  },
};

export default PaymentSuccessPageFpx;
