import React, { useState, useEffect } from 'react';

function CountdownTimer() {
  const [timeLeft, setTimeLeft] = useState({});
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    const targetDate = new Date();
    targetDate.setDate(targetDate.getDate() + 2);
    targetDate.setHours(targetDate.getHours() + 5);
    targetDate.setMinutes(targetDate.getMinutes() + 23);
    targetDate.setSeconds(targetDate.getSeconds() + 45);
    setEndDate(formatDate(targetDate));

    const timer = setInterval(() => {
      const now = new Date();
      const diff = targetDate - now;

      if (diff <= 0) {
        clearInterval(timer);
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      } else {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    };

    return `${day}${daySuffix(day)} ${month} ${year}`;
  };

  return (
    <div style={{
      padding: '2rem',
      background: 'linear-gradient(135deg, #FFF0F5 0%, #FFB6C1 100%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: "'Arial', sans-serif",
      boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
      borderRadius: '20px',
      marginLeft: '2rem',
      marginRight: '2rem'
    }}>
      <style>
        {`
          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.05); }
            100% { transform: scale(1); }
          }
          .time-unit {
            transition: all 0.3s ease;
          }
          .time-unit:hover {
            transform: scale(1.05);
          }
        `}
      </style>

      {/* Stopwatch Icon */}
      <div style={{
        width: '40px',
        height: '40px',
        marginBottom: '1rem',
        animation: 'pulse 2s infinite'
      }}>
        <svg viewBox="0 0 24 24" fill="none" stroke="#FF1493" strokeWidth="2" style={{
          width: '100%',
          height: '100%'
        }}>
          <circle cx="12" cy="12" r="10"/>
          <polyline points="12 6 12 12 16 14"/>
        </svg>
      </div>

      <h2 style={{
        color: '#FF1493',
        fontSize: '1.25rem',
        marginBottom: '1.5rem',
        textAlign: 'center',
        fontWeight: '500'
      }}>
        Tamat Pada: {endDate}
      </h2>

      <div style={{
        display: 'flex',
        gap: '0.2rem',
        justifyContent: 'center',
        padding: '1rem',
        background: 'rgba(255,255,255,0.9)',
        borderRadius: '15px',
        boxShadow: '0 5px 15px rgba(255,105,180,0.2)',
        paddingLeft: '1rem',
        paddingReft: '1rem',
      }}>
        {Object.entries(timeLeft).map(([unit, value]) => (
          <div key={unit} className="time-unit" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0.8rem',
            minWidth: '70px',
            background: 'white',
            borderRadius: '10px',
          }}>
            <span style={{
              fontSize: '1.75rem',
              fontWeight: 'bold',
              color: '#FF1493',
              lineHeight: '1'
            }}>
              {value}
            </span>
            <span style={{
              fontSize: '0.75rem',
              color: '#FF69B4',
              textTransform: 'uppercase',
              marginTop: '0.3rem'
            }}>
              {unit}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CountdownTimer;