import React, { useRef, useState } from 'react';
import PlayButton from './PlayButton';
import './VideoPlayer.css';

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleVideoLoad = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);

      // Pause the video after 2 seconds
      setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.pause();
          setIsPlaying(false);
        }
      }, 0); // 2000ms = 2 seconds
    }
  };

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        width="100%"
        preload="auto"
        poster="/thumbnail.jpg"
        onLoadedData={handleVideoLoad}
        onClick={handlePlayPause}
      >
        <source src="/480.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {!isPlaying && <PlayButton onClick={handlePlayPause} />}
    </div>
  );
};

export default VideoPlayer;
