import React, { useState, useEffect } from "react";

const PromoBanner = () => {
  // Set the initial timer state in seconds
  const [timeLeft, setTimeLeft] = useState(6 * 3600 + 17 * 60 + 32);

  useEffect(() => {
    // Set up an interval to update the timer every second
    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  // Function to format the time as hh:mm:ss
  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  return (
    <div
      style={{
        backgroundColor: "#F06292",
        width: "100%",
        height: "90px",
        display: "flex",
        flexDirection: "column", // Added this line
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ color: "white", fontSize: "24px", fontWeight: "bold" }}>
        {formatTime(timeLeft)}
      </div>

      <h1 style={{ color: "white", margin: "0" }}>
        Dapatkan Harga Promosi Di Bawah
      </h1>
    </div>
  );
};

export default PromoBanner;
