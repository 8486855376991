import React, { useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa"; // Import WhatsApp icon
import "./MobileImagePage.css";
import Image1 from "./1.png"; 
import Image2 from "./2.jpg"; 
import Image3 from "./3.jpg"; 
import Image9 from "./9.jpg"; 
import Image10 from "./10.jpg"; 
import Image11 from "./11.jpg"; 

const MobileImagePage = () => {
  useEffect(() => {
    const loadFacebookPixel = () => {
      (function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = true;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = true;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

      window.fbq = window.fbq || function() {};
      window.fbq("init", "295669516421830"); // Replace with your Pixel ID
      window.fbq("track", "PageView");
    };

    loadFacebookPixel();
  }, []);

  const handleWhatsAppClick = () => {
    if (window.fbq) {
      window.fbq("track", "Lead");
    }
    window.location.href = "https://api.whatsapp.com/send?phone=60183943250&text=PromosiSlimmingPanties";
  };

  return (
    <div className="image-container">
      <div style={{ backgroundColor: "purple", width: "100%" }}>
        <h1 style={{ color: "white", fontSize: "15px" }}>Aizira HQ</h1>
      </div>
      <div style={{ backgroundColor: "#f0ca22", width: "100%" }}>
        <h1 style={{ color: "black" }}>Promosi End Year Tahun 2024!</h1>
      </div>
      <div style={{ backgroundColor: "red", width: "100%" }}>
        <h1 style={{ color: "white", fontSize: "13px" }}>Barang Sampai Baru Bayar!</h1>
      </div>
      <img src={Image2} alt="Mobile full width" className="full-width-image" />
      <button onClick={handleWhatsAppClick}>
        Whatsapp Sekarang! <FaWhatsapp />
      </button>
      <img src={Image3} alt="Mobile full width" className="full-width-image" />
      <button onClick={handleWhatsAppClick}>
        Whatsapp Sekarang! <FaWhatsapp />
      </button>
      <img src={Image9} alt="Mobile full width" className="full-width-image" />
      <img src={Image11} alt="Mobile full width" className="full-width-image" />
      <img src={Image10} alt="Mobile full width" className="full-width-image" />
      <button onClick={handleWhatsAppClick}>
        Whatsapp Sekarang! <FaWhatsapp />
      </button>
    </div>
  );
};

export default MobileImagePage;
