import React, { useEffect, useState, useRef } from "react";
import "./MobileImagePage.css";
import Image1 from "./img1/Sample-Logo-square.png";
import Destinations from "./Destinations";
import CenteredContent from "./CenteredContent";
import FAQMobileView from "./FaqMobileView";
import "react-lazy-load-image-component/src/effects/blur.css";
import PromoBanner from "./PromoBanner";
import { useNavigate } from 'react-router-dom';
import imgreview1 from './img1/9.jpg';
import imgreview2 from './img1/10.jpg';
import imgreview3 from './img1/11.jpg';
import img7warnamenarik from './img1/7warnamenarik.jpg';
import imgprice1 from './img1/price1.jpg'
import imgprice2 from './img1/price2.jpg'
import VideoPlayer from "./VideoPlayer";
import lucky from './img1/lucky.jpg'
import img12 from './img1/12.jpg'
import img13 from './img1/13.jpg'
import CountdownTimer from "./CountdownTimer";
import logo1 from './img1/hibiscus.png'
import img14 from './img1/size.JPG'
import g1 from './img1/g1.webp'
import g2 from './img1/g2.webp'
import g3 from './img1/g3.webp'
import g4 from './img1/g4.webp'
import g5 from './img1/g5.webp'

const Page1 = () => {

  const hasTracked = useRef(false); // Flag to check if the event has been tracked

  useEffect(() => {
    if (window.fbq && !hasTracked.current) {
      window.fbq('track', 'ViewContent'); // Fire the event once
      hasTracked.current = true; // Set the flag to true after firing the event
    }
  }, []); // Fires only once when the component mounts


    const navigate = useNavigate();

    const [selectedColors, setSelectedColors] = useState([]);
    const [hoveredButton, setHoveredButton] = useState(null);

    const colors = [
      { id: 1, color: '#E9979A', icon: '', name: 'Red' }, //merah
      { id: 2, color: '#D6DFD5', icon: '', name: 'Green' }, // hijau
      { id: 3, color: '#D5E7F3', icon: '', name: 'Blue' }, // biru
      { id: 4, color: '#DCAE95', icon: '', name: 'Orange' }, // jigga
      { id: 5, color: '#A398B3', icon: '', name: 'Purple' }, //ungu
      { id: 6, color: '#FAE5D8', icon: '', name: 'Peach' }, //peach
      { id: 7, color: '#F0DEA9', icon: '', name: 'Yellow' }, //yellow
    ];
  
    const orderform1 = () => {
      navigate('/ColorPicker');
    };

    const orderform2 = () => {
      navigate('/ColorPicker14');
    };
  
  const handleWhatsAppClick = () => {
    if (window.fbq) {
      window.fbq("track", "Lead");
    }
    window.location.href = "https://api.whatsapp.com/send?phone=60183943250&text=PromosiSlimmingPanties";
  };

  const imageArray1 = [Image1]; // Array for repeated images
  const imageArray = [img7warnamenarik]; // Array for repeated images
  const imageArrayReview = [imgreview1, imgreview2, imgreview3];
  const imageArrayPricing1 = [imgprice1]; // Array for repeated images
  const imageArrayPricing2 = [imgprice2]; // Array for repeated images
  const imageArrayRLucky = [lucky]; // Array for repeated images
  const imageArray1213 = [img12]; // Array for repeated images
  const imageArrayshow = [img13]; // Array for repeated images
  const imageArraysaiz = [img14]; // Array for repeated images
  const imageArray2 = [g1, g2, g3]; // Array for repeated images
  const imageArray3 = [g4, g5]; // Array for repeated images

  return (
    <div className="image-container">
      <div style={{ backgroundColor: "#FF8892", width: "100%", paddingTop: '5px' }}>
      <img src={logo1} alt="Cash on Delivery" style={{height: '60px'}} />
      <h1 style={{ color: "white", fontSize: "26px", marginTop: '-10px', fontFamily: "'Sofia Sans Extra Condensed', sans-serif" }}>
        Aizira MY
      </h1>
      </div>
      <div style={{ backgroundColor: "#f0ca22", width: "100%" }}>
        <h1 style={{ color: "black" }}>Promosi New Year Tahun 2025!</h1>
      </div>
      <div style={{ backgroundColor: "red", width: "100%" }}>
        <h1 style={{ color: "white", fontSize: "19px" }}>Barang Sampai Baru Bayar!</h1>
      </div>

      {imageArray1213.map((image, index) => (
        <React.Fragment key={index}>
          <img src={image} alt="Mobile full width" className="full-width-image" />
        </React.Fragment>
      ))}

      <CenteredContent 
        title="Nak nampak lebih ramping dan langsing dalam sekelip mata?" 
        description="Jom Dapatkan Slimming High Waist Panties ni Sekarang!" 
        buttonText="Beli Sekarang"
        backgroundColor="#f7f7f7" // Set background color here
      />

      


      <div style={{ backgroundColor: "#F06292", width: "100%", height: "90px", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <h1 style={{ color: "white", marginLeft: "20px", marginRight: "20px"}}>High Quality Fabrik, Boleh Stretch dan Selesa</h1>
      </div>
      
      {imageArray2.map((image, index) => (
        <React.Fragment key={index}>
          <img src={image} alt={`Image ${index + 1}`} className="full-width-image" />
        </React.Fragment>
      ))}


    <div style={{ backgroundColor: "#F06292", width: "100%", height: "90px", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <h1 style={{ color: "white", marginLeft: "20px", marginRight: "20px"}}>Play Video Di Bawah</h1>
      </div>
      <VideoPlayer />



      <div style={{ backgroundColor: "#F06292", width: "100%", height: "90px", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <h1 style={{ color: "white", marginLeft: "20px", marginRight: "20px"}}>The Only Panties Yang Sisters Perlu</h1>
      </div>


      {imageArray.map((image, index) => (
        <React.Fragment key={index}>
          <img src={image} alt={`Image ${index + 1}`} className="full-width-image" />
        </React.Fragment>
      ))}

      <div style={{ backgroundColor: "#F06292", width: "100%", height: "90px", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <h1 style={{ color: "white", marginLeft: "20px", marginRight: "20px"}}>Kenapa Kena Beli Dengan Aizira MY?</h1>
      </div>

      <Destinations />

      {imageArray3.map((image, index) => (
        <React.Fragment key={index}>
          <img src={image} alt={`Image ${index + 1}`} className="full-width-image" />
        </React.Fragment>
      ))}

      <div style={{ backgroundColor: "#F06292", width: "100%", height: "90px", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <h1 style={{ color: "white", marginLeft: "20px", marginRight: "20px"}}>Jom Tengok Review2 Customer Yang Dah Beli Dengan Kami</h1>
      </div>

      {imageArrayReview.map((image, index) => (
        <React.Fragment key={index}>
          <img src={image} alt={`Image ${index + 1}`} className="full-width-image" />
        </React.Fragment>
      ))}


      <div style={{ backgroundColor: "#F06292", width: "100%", height: "90px", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <h1 style={{ color: "white", marginLeft: "20px", marginRight: "20px"}}>Sis Boleh Pilih Dari 7 Warna Menarik</h1>
      </div>

      <div style={styles.buttonContainer}>
        {colors.map((color) => (
          <button
            key={color.id}
            onMouseEnter={() => setHoveredButton(color.id)}
            onMouseLeave={() => setHoveredButton(null)}
            style={{
              ...styles.colorButton,
              backgroundColor: color.color,
              border: hoveredButton === color.id ? '2px solid black' : 'none',
              height: '80px'
            }}
          >
            {color.icon}{color.name}
          </button>
        ))}
      </div>
      

      {imageArrayshow.map((image, index) => (
        <React.Fragment key={index}>
          <img src={image} alt={`Image ${index + 1}`} className="full-width-image" />
        </React.Fragment>
      ))}



      <div style={{ backgroundColor: "#ffc6dc", width: "100%", height: "130px", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <h1 style={{ color: "maroon", marginLeft: "20px", marginRight: "20px"}}>Jom join Lucky Draw Yang Sedang Berlangsung Dengan Mana-mana Pembelian</h1>
      </div>



      {imageArrayRLucky.map((image, index) => (
        <React.Fragment key={index}>
          <img src={image} alt={`Image ${index + 1}`} className="full-width-image" />
        </React.Fragment>
      ))}

      <div style={{ backgroundColor: "#F06292", width: "100%", height: "60px", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <h1 style={{ color: "white", marginLeft: "20px", marginRight: "20px"}}>Tempoh Lucky Draw Kalini ⬇️</h1>
      </div>

      <CountdownTimer />



      <div style={{ backgroundColor: "#F06292", width: "100%", height: "90px", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <h1 style={{ color: "white", marginLeft: "20px", marginRight: "20px"}}>Boleh Refer Pada Sizing Chart Kami 👇</h1>
      </div>

      {imageArraysaiz.map((image, index) => (
        <React.Fragment key={index}>
          <img src={image} alt={`Image ${index + 1}`} className="full-width-image" />
        </React.Fragment>
      ))}


      <CenteredContent 
        title="Nak Dapatkan Diskaun Serta-Merta?" 
        description="Jom Beli Hari ini dan Nikmati diskaun RM10 dengan pembayaran FPX, atau boleh COD" 
        buttonText="Beli Sekarang"
        backgroundColor="#f7f7f7" // Set background color here
      />

      <div style={{ backgroundColor: "red", width: "100%", height: "90px", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <h1 style={{ color: "white", marginLeft: "20px", marginRight: "20px"}}>Dapatkan Diskaun Ketika Checkout ✅</h1>
      </div>

      <PromoBanner />

      {/* PRICE1 */}
      {imageArrayPricing1.map((image, index) => (
        <React.Fragment key={index}>
          <img src={image} alt="Mobile full width" className="full-width-image" />
        </React.Fragment>
      ))}
    <div style={{ backgroundColor: "#d9f062", width: "100%", height: "90px", display: "flex", justifyContent: "center", alignItems: "center" }}>
    <button   
      onClick={orderform1}
      style={{ padding: "20px 40px", marginBottom: "100px", fontSize: "20px", fontWeight: "bold", backgroundColor: "#ff5722", color: "#fff", border: "none", borderRadius: "12px", cursor: "pointer", transition: "background-color 0.3s ease", textTransform: "uppercase" }}>
        Beli Set Trial
      </button>
    </div>

      {/* PRICE2 */}
      {imageArrayPricing2.map((image, index) => (
        <React.Fragment key={index}>
          <img src={image} alt="Mobile full width" className="full-width-image" />
        </React.Fragment>
      ))}
    <div style={{ backgroundColor: "#62f0c0", width: "100%", height: "90px", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <button   
        onClick={orderform2}
        style={{ padding: "20px 40px", marginBottom: "100px", fontSize: "20px", fontWeight: "bold", backgroundColor: "#ff5722", color: "#fff", border: "none", borderRadius: "12px", cursor: "pointer", transition: "background-color 0.3s ease", textTransform: "uppercase" }}>
        Beli Set Combo
      </button>
    </div>
    <FAQMobileView />
    </div>
  );
};

const styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1px',
    marginBottom: '30px',
  },
  colorButton: {
    width: '80vw',
    height: '42px',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'border 1s ease, transform 0.3s ease', // Added transition for transform
    transform: 'scale(1)', // Default scale
  },
  colorButtonHover: {
    transform: 'scale(1.05)', // Grow effect when hovered
  }
};


export default Page1;
