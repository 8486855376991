import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import img9 from './img1/price1.jpg'
import logo1 from './img1/hibiscus.png'
import img14 from './img1/size.JPG'

const ColorPicker = () => {

  useEffect(() => {
    // Ensure that the event is only triggered once and Pixel is initialized correctly
    if (window.fbq && !window.fbq._initialized) {
      window.fbq('track', 'InitiateCheckout', {
        value: 99.00,  // Replace with the actual purchase value
        currency: 'MYR',  // Replace with the actual currency
      });

      // Mark the Pixel as initialized to prevent re-triggering
      window.fbq._initialized = true;
    }
  }, []);

  const navigate = useNavigate(); // Using useNavigate hook for navigation
  const [selectedSize, setSelectedSize] = useState(null); // State to track selected size
  const [selectedColors, setSelectedColors] = useState([]);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [hoveredTag, setHoveredTag] = useState(null);

  const colors = [
    { id: 1, color: '#E9979A', icon: '', name: 'Red' }, //merah
    { id: 2, color: '#D6DFD5', icon: '', name: 'Green' }, // hijau
    { id: 3, color: '#D5E7F3', icon: '', name: 'Blue' }, // biru
    { id: 4, color: '#DCAE95', icon: '', name: 'Orange' }, // jigga
    { id: 5, color: '#A398B3', icon: '', name: 'Purple' }, //ungu
    { id: 6, color: '#FAE5D8', icon: '', name: 'Peach' }, //peach
    { id: 7, color: '#F0DEA9', icon: '', name: 'Yellow' }, //yellow
  ];

  const handleSubmit = () => {
    const combinedData = [{ size: selectedSize }, ...selectedColors]; // Combine selectedSize with selectedColors
    navigate('/orderform1', { state: { combinedData } }); // Pass combinedData to the next page via navigate
  };

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  const handleColorSelect = (color) => {
    const uniqueColor = { ...color, uniqueId: `${color.id}-${new Date().getTime()}` };
    if (selectedColors.length < 7) {
      setSelectedColors((prev) => {
        const updatedColors = [...prev, uniqueColor];
        console.log(updatedColors); // log updated state directly here
        return updatedColors;
      });
    }
  };

  const handleColorRemove = (uniqueId) => {
    setSelectedColors((prev) => {
      const updatedColors = prev.filter(color => color.uniqueId !== uniqueId);
      console.log(updatedColors); // log updated state directly here
      return updatedColors;
    });
  };

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  // Log selectedColors whenever it changes
  useEffect(() => {
    console.log(selectedColors);
  }, [selectedColors]);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const closePopup = () => setIsPopupVisible(false);

  return (
    <div style={styles.container}>
    <div style={{ backgroundColor: "white", width: "100%", textAlign: "center" }}>
    <img src={logo1} alt="Cash on Delivery" style={{height: '60px'}} />
      <h1 style={{ color: "#C03765", fontSize: "26px", marginTop: '-10px', fontFamily: "'Sofia Sans Extra Condensed', sans-serif" }}>
        Aizira MY
      </h1>
    </div>
        <h2 style={{ color: "green", marginTop: '-10px', fontSize: "25px", fontFamily: "'Sofia Sans Extra Condensed', sans-serif"}}>
            SET Trials 7 Helai
        </h2>
        <div style={styles.imgContainer}>
            <img src={img9} alt="Cash on Delivery" style={styles.img} />
        </div>
        <p style={{ fontSize: '15px', color: 'blue', textDecoration: 'underline', marginBottom: '-15px', cursor: 'pointer' }} onClick={() => setIsPopupVisible(true)}>Sizing Chart</p>
        {isPopupVisible && (
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} onClick={closePopup}>
            <div style={{ position: 'relative', backgroundColor: '#fff', padding: '20px', borderRadius: '8px', zIndex: 1010 }} onClick={(e) => e.stopPropagation()}>
              <img src={img14} alt="Popup" style={{ maxWidth: '100%', maxHeight: '80vh', borderRadius: '4px' }} />
              <p style={{ fontSize: '18px' }}>
                <strong>Nota:</strong> Panties ini sangat stretcheable dan anjal, boleh consider saiz yang sama dengan seluar sisters2 sekalian
              </p>              
              <p style={{ fontSize: '18px', marginTop: '-27px'}}>Pemulangan jika ingin menukar saiz juga percuma</p>
              <button onClick={closePopup} style={{ position: 'absolute', top: '10px', right: '10px', background: 'red', color: '#fff', border: 'none', borderRadius: '50%', width: '30px', height: '30px', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', fontWeight: 'bold', lineHeight: '1' }}>X</button>
            </div>
          </div>
        )}
      <h3 style={styles.heading}>Sila Pilih Saiz</h3>

      <div style={styles.sizeContainer}>
        <button
          onClick={() => handleSizeSelect('L')}
          style={{
            ...styles.sizeButton,
            backgroundColor: selectedSize === 'L' ? '#4A90E2' : 'white',
            color: selectedSize === 'L' ? 'white' : 'black',
          }}
        >
          L (40kg - 65kg)
        </button>
        <button
          onClick={() => handleSizeSelect('XL')}
          style={{
            ...styles.sizeButton,
            backgroundColor: selectedSize === 'XL' ? '#4A90E2' : 'white',
            color: selectedSize === 'XL' ? 'white' : 'black',
          }}
        >
          XL (65kg - 100kg)
        </button>
      </div>

      <h3 style={styles.heading}>Sila Pilih Warna</h3>
      <div style={styles.buttonContainer}>
        {colors.map((color) => (
          <button
            key={color.id}
            onClick={() => handleColorSelect(color)}
            onMouseEnter={() => setHoveredButton(color.id)}
            onMouseLeave={() => setHoveredButton(null)}
            style={{
              ...styles.colorButton,
              backgroundColor: color.color,
              border: hoveredButton === color.id ? '2px solid black' : 'none',
            }}
          >
            {color.icon}
          </button>
        ))}
      </div>

      <h3 style={styles.heading}>Selected ({selectedColors.length}/7):</h3>
      <div style={styles.selectedContainer}>
        {selectedColors.map((color) => (
          <div key={color.uniqueId} style={{ ...styles.selectedColor, backgroundColor: color.color }}>
            <button
              onClick={() => handleColorRemove(color.uniqueId)}
              style={styles.removeButton}
            >
              ×
            </button>
          </div>
        ))}
      </div>

      <button
        style={{
          ...styles.checkoutButton,
          backgroundColor: selectedColors.length >= 7 && selectedSize ? '#28a745' : '#A0AEC0',
        }}
        disabled={selectedColors.length < 7 || !selectedSize}
        onClick={handleSubmit}
      >
        Checkout ({selectedColors.length}/7 selected)
      </button>
      <p style={{ color: "grey", fontSize: "20px", fontFamily: "'Sofia Sans Extra Condensed', sans-serif", fontStyle: "italic" }}>
            Nota: Warna default adalah seperti di dalam gambar, sila pilih warna yang available sementara stok masih ada!
        </p>
    </div>
  );
};
// Styles object
const styles = {
  container: {
    width: '100vw',
    maxWidth: '400px',
    margin: '0 auto',
    padding: '24px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    marginTop: '0px'
  },

  heading: {
    fontSize: '18px',
    marginBottom: '12px',
    fontWeight: 'bold',
  },
  sizeContainer: {
    display: 'flex',
    gap: '16px',
    marginBottom: '24px',
  },
  sizeButton: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    border: '2px solid #ccc',
    borderRadius: '8px',
    backgroundColor: 'white',
    transition: 'background-color 0.3s ease',
    color: 'black',
  },
  buttonContainer: {
    display: 'flex',
    gap: '8px',
    marginBottom: '24px',
  },
  colorButton: {
    width: '48px',
    height: '42px',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'border 0.3s ease',
  },
  selectedContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    marginBottom: '24px',
  },
  selectedColor: {
    width: '42px',
    height: '42px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  removeButton: {
    position: 'absolute',
    top: '-8px',
    right: '-8px',
    background: 'white',
    border: 'none',
    color: '#FF0000',
    fontSize: '18px',
    cursor: 'pointer',
    padding: '2px 6px',
    borderRadius: '50%',
  },
  checkoutButton: {
    width: '100%',
    padding: '12px',
    backgroundColor: '#A0AEC0',
    color: 'white',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  imgContainer: {
    width: '100%', // Ensures the image container takes full width
    display: 'flex',
    justifyContent: 'center', // Center the image horizontally
    alignItems: 'center', // Center the image vertically
    marginBottom: '20px', // Adjust the margin for spacing
  },
  img: {
    width: '100%', // Make image take up full width of its container
    height: 'auto', // Maintain aspect ratio
    objectFit: 'cover', // Ensure the image covers the entire container
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add subtle shadow
    borderRadius: '5px', // Optional: Adds subtle rounded corners
  },
};

export default ColorPicker;
